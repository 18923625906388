import React from "react";
import PhoneContext from "../../contexts/phoneContext";
import { Thankyou } from "../../components/utility/quote";
import { Router } from "@reach/router";
import Question1 from "./question1";
import Question2 from "./question2";
import Question3 from "./question3";
import Form from "./Form";
import { Product_Quote_Routes } from "../../data/Routes";
import { useQuestionState } from "../../question_components/hooks/useQuestionState";
import { routeDetails } from "../../question_components/data/PhoneSystem";
import firebase from "gatsby-plugin-firebase";
import { FAQS } from "../../data/product-page-data/BusinessPhoneData";
import axios from "axios";



const Quote = () => {
  const {
    que1,
    que2,
    que3,
    setQue1,
    setQue2,
    setQue3,
    formValues,
    setFormValues,
  } = useQuestionState();

  const addLeadToBackend = async (values) => {
    await fetch("/.netlify/functions/sendAdmin", {
      method: "POST",
      body: JSON.stringify({
        data: `
        <h1>Lead genrated for: Business Phone System</h1><br>
        <h4>Customer Details</h4><br>
        <ul>
        <li>Full name: <b>${values.fullname}</b></li><br>
        <li>Email: <b>${values.email}</b></li><br>
        <li>Company name: <b>${values.companyname}</b></li><br>
        <li>Phone number: <b>${values.phonenumber}</b></li><br>
        <li>Postcode: <b>${values.postcode}</b></li><br>
        </ul>
        <p>1.Which best describes your phone system needs?:<br><b>${que1}</b></p><br>
        <p>2.How many handsets or user extensions do you need?:<br><b>${que2}</b></p><br>
        <p>3.What features do you require?:<br> <b>${que3}</b></p><br>
        <p>4.Has Your Business Moved To The NBN Yet?:<br><b>Yes</b></p><br>
        <p>5.When Are You Likely To Buy A Phone System?:<br><b>ASAP</b></p>`,
      }),
    });
    var timestamp = firebase.firestore.FieldValue.serverTimestamp();
    await firebase.firestore().collection("Phone_System").add({
      customerDetails: values,
      "How many vehicles require tracking?": que1,
      "What type of vehicle need GPS tracking?": que2,
      "What features do you require?": que3,
      Lead_Generated: timestamp,
    });
    try {
      const response = fetch("/.netlify/functions/sendgrid", {
        method: "POST",
        body: JSON.stringify({
          email: values.email,
          subject: "Digital Comparison",
          name: values.fullname,
        }),
      });
      if (!response.ok) {
        //not 200 response
        return;
      }
    } catch (e) {
      //error
      console.log(e);
    }
  };

  return (
    <PhoneContext.Provider
      value={{
        que1,
        que2,
        que3,
        setQue1,
        setQue2,
        setQue3,
        formValues,
        setFormValues,
        addLeadToBackend,
      }}
    >
      <Router>
        <section path={Product_Quote_Routes.BusinessPhoneSystem}>
          <Question1 path={routeDetails.q1} />
          <Question2 path={routeDetails.q2} />
          <Question3 path={routeDetails.q3} />
          <Question3 path={routeDetails.q3} />
          <Form title={routeDetails.title} path={routeDetails.form} />
          <Thankyou
            FaqData={FAQS}
            title={routeDetails.title}
            path={routeDetails.thankyou}
          />
        </section>
      </Router>
    </PhoneContext.Provider>
  );
};

export default Quote;
