import React, { useContext } from "react";
import { CustomerDetails } from "../../components/Form/CustomerDetails";
import addToMailchimp from "gatsby-plugin-mailchimp";
import PhoneContext from "../../contexts/phoneContext";
import {
  QueHeader,
  QueLayout,
  QueFormContainer,
  OurPartners,
} from "../../question_components/container";
import { FAQS } from "../../data/product-page-data/BusinessPhoneData";
import { navigate } from "gatsby";

export default function Form({ title }) {
  const { setFormValues, addLeadToBackend } = useContext(PhoneContext);

  const subscribeMC = async (values) => {
    await addToMailchimp(values.email, {
      FNAME: values.fullname,
      COMNAME: values.companyname,
      PHONE: values.phonenumber,
      PINCODE: values.postcode,
      PRONAME: title,
    });
  };

  return (
    <QueLayout FaqData={FAQS}>
      <QueHeader
        title="Business Phone System"
        progress={90}
        question="Please provide your few contact details."
        info="All field are mandatory."
      />
      <QueFormContainer>
        <CustomerDetails
          onSubmit={(values, actions) => {
            setFormValues(values);
            const timeOut = setTimeout(() => {
              actions.setSubmitting(false);
              clearTimeout(timeOut);
            }, 1000);
            addLeadToBackend(values);
            subscribeMC(values);
            navigate("/phone-system/Questions/thankyou");
          }}
        />
      </QueFormContainer>
      <OurPartners />
    </QueLayout>
  );
}
