import React, { useContext, useState } from "react";
import {
  QueHeader,
  QueLayout,
  QueRadioOptions,
  CheckBox,
  OurPartners,
} from "../../question_components/container";
import { FAQS } from "../../data/product-page-data/BusinessPhoneData";
import { Que3Data } from "../../question_components/data/PhoneSystem";
import PhoneContext from "../../contexts/phoneContext";

export default function Question3() {
  const { que3, setQue3 } = useContext(PhoneContext);
  const [isChecked, setIsChecked] = useState(false);

  return (
    <QueLayout FaqData={FAQS}>
      <QueHeader
        title="Let Us Find You The Best Business Phone System"
        progress={50}
        number="Question 3"
        question="What features do you require?"
        info="You can choose multiple options from below."
      />
      {Que3Data.map((item) => {
        return (
          <QueRadioOptions
            checkBox
            error={que3.length === 0 ? true : false}
            back={item.back}
            next={que3.length === 0 ? null : item.next}
          >
            {item.options.map((items) => {
              return (
                <CheckBox
                  key={items.answer}
                  icon={<items.icon />}
                  value={items.answer}
                  title={items.answer}
                  onChange={(e) => {
                    setQue3([]);
                    setIsChecked(!isChecked);
                    setQue3([...que3, items.answer]);
                  }}
                />
              );
            })}
          </QueRadioOptions>
        );
      })}
      <OurPartners />
    </QueLayout>
  );
}
