import { useState } from "react";

export function useQuestionState() {
  const [que1, setQue1] = useState(null);
  const [que2, setQue2] = useState(null);
  const [que3, setQue3] = useState([]);
  const [que4, setQue4] = useState(null);
  const [que5, setQue5] = useState(null);
  const [formValues, setFormValues] = useState(null);

  return {
    que1,
    setQue1,
    que2,
    setQue2,
    que3,
    setQue3,
    que4,
    setQue4,
    que5,
    setQue5,
    formValues,
    setFormValues,
  };
}
